import {from, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {BaseEntry} from '../domain';
import {BlogRepository} from '../repository/blog-repository';

@Injectable()
export class InsiderEntryResolver implements Resolve<Observable<BaseEntry>> {
  constructor(private blogRepository: BlogRepository) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<BaseEntry> {
    return from(this.blogRepository.fetchInsiderEntry(route.params.id));
  }
}
