<div class="index-grid">
  <div class="top-nav-bar">
    <div routerLink="/" class="top-nav-logo">
      <img src="../assets/portfolio-insight-logo-white.svg" width="45" alt="" class="header__logo-img">
    </div>
    <div class="top-nav-pi-link"><a *ngIf="showIndex()" routerLink="/">Back to Index</a></div>
    <div class="top-nav-pi-link"><a href="https://www.portfolio-insight.com">Portfolio Insight &#8599;</a></div>
  </div>
  <router-outlet></router-outlet>
</div>

