import {Injectable} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {BaseEntry} from '../domain';
import {toHTML} from '@portabletext/to-html';
import {imageBuilder} from '../util';

@Injectable()
export class ContentService {

  constructor(private sanitizer: DomSanitizer) {
  }

  parseContent(entry: BaseEntry): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(toHTML(entry.content, {
      components: {
        types: {
          image: ({value}) => {
            // Setup a DOM even listener to invoke lightbox() which is defined in index.html.
            // Angular components cannot be referenced from sanitized HTML.
            return `<div class="clickable" onclick="lightbox('${imageBuilder().image(value).url()}')" class="image-container">`
              + `<img src="${imageBuilder().image(value).url()}" alt=""/>`
              + `</a></div>`
              + `<div class="image-caption">Click to enlarge</div>`;
          },
          vimeo: props => {
            const vimeoId = (<any> props).value.url.split('/')[3];
            return `<div class="vimeo-frame"><iframe src="https://player.vimeo.com/video/${vimeoId}?h=5426d0b958&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Portfolio Insight&amp;#039;s Dividend Quality Grade"></iframe></div>`;
          },

        },
        block: <any> {
          singleline: ({children}: any) => {
            return `<div class="singleline">${children}</div>`;
          },
          bulletheading: ({children}: any) => {
            return `<div class="bullet-heading">${children}</div>`;
          },
          imagetitle: ({children}: any) => {
            return `<div class="image-title">${children}</div>`;
          },
          highlightbox: ({children}: any) => {
            return `<div class="highlight-box">${children}</div>`;
          },
        },
        marks: {
          tableLink: ({children, value}) => {
            // console.log((<any> children));
            // console.log(value.table);
            let rendered = '';
            let heading = true;
            if (entry == null) {
              return '';
            }
            (<any> entry)[`table${value.table}`].rows.forEach((row: any) => {
              rendered += heading ? `<thead>` : `<tr>`;
              let cellNumber = 0;
              row.cells.forEach((cell: any) => {
                if (cellNumber === 0) {
                  rendered += heading ? `<th style="text-align:left">${cell}</th>` : `<td style="text-align:left">${cell}</td>`;
                } else {
                  rendered += heading ? `<th>${cell}</th>` : `<td>${cell}</td>`;
                }
                cellNumber++;
              });
              rendered += heading ? `</thead>` : `</tr>`;
              heading = false;
            });
            return `<table class="data-table">${rendered}</table><div class="table-caption">${children}</div>`;
          }
        }
      },
    }));
  }

  parseAuthor(insiderEntry: BaseEntry): SafeHtml | null {
    if (insiderEntry.resolvedAuthor != null) {
      return this.sanitizer.bypassSecurityTrustHtml(toHTML(insiderEntry.resolvedAuthor.bio, {
        components: {
          /* optional object of custom components to use */
        },
      }));
    }
    return null;

  }
}
