import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BlogEntry, getCategoryDescription} from '../domain';

import {toHTML} from '@portabletext/to-html';
import {DomSanitizer, Meta, SafeHtml, Title} from '@angular/platform-browser';
import {imageBuilder} from '../util';
import {ContentService} from '../content/content.service';

@Component({
  selector: 'app-blog-entry',
  templateUrl: './blog-entry.component.html'
})
export class BlogEntryComponent implements OnInit {
  blogEntry: BlogEntry | undefined;
  content: SafeHtml | undefined;
  bioContent: SafeHtml | undefined | null;

  constructor(private contentService: ContentService,
              private route: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta) {
    this.route.data.subscribe(data => {
      this.blogEntry = <BlogEntry> data.blogEntry;

      this.titleService.setTitle('Portfolio Insight - ' + this.blogEntry.title);
      this.metaService.addTag({name: 'description', content: this.blogEntry.synopsis}, true);

      this.content = this.contentService.parseContent(this.blogEntry);
      this.bioContent = this.contentService.parseAuthor(this.blogEntry);
    });
  }

  ngOnInit(): void {

  }

  categoryDescription(category: string) {
    return getCategoryDescription(category);
  }
}
