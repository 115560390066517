import imageUrlBuilder from '@sanity/image-url';

declare var require: any;

const client = require('@sanity/client')({
  projectId: 'brotnxmf',
  dataset: 'snapshots',
  apiVersion: '2021-03-25',
  useCdn: true
});

export function sanityClient() {
  return client;
}

export function imageBuilder() {
  // Get a pre-configured url-builder from your sanity client
  return imageUrlBuilder(client);
}
