import {APP_INITIALIZER, enableProdMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BlogRepository} from './repository/blog-repository';
import {EntriesListComponent} from './entry/entries-list.component';
import {BlogEntryComponent} from './entry/blog-entry.component';
import {BlogEntryResolver} from './entry/blog-entry.resolver';
import {from, Observable} from 'rxjs';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {InsiderEntryComponent} from './insider/insider-entry.component';
import {InsiderEntryResolver} from './insider/insider-entry.resolver';
import {ContentService} from './content/content.service';

@NgModule({
  declarations: [
    AppComponent,
    EntriesListComponent,
    BlogEntryComponent,
    InsiderEntryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InfiniteScrollModule
  ],
  providers: [
    BlogRepository,
    BlogEntryResolver,
    InsiderEntryResolver,
    ContentService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [BlogRepository],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}

enableProdMode();

function initializeAppFactory(blogRepository: BlogRepository): () => Observable<void> {
  return () => from(blogRepository.initialize());
}
