import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseEntry, getCategoryDescription} from '../domain';
import {DomSanitizer, Meta, SafeHtml, Title} from '@angular/platform-browser';
import {ContentService} from '../content/content.service';

@Component({
  selector: 'app-insider-entry',
  templateUrl: './insider-entry.component.html'
})
export class InsiderEntryComponent implements OnInit {
  insiderEntry: BaseEntry | undefined;
  content: SafeHtml | undefined;
  bioContent: SafeHtml | undefined | null;

  constructor(private sanitizer: DomSanitizer,
              private contentService: ContentService,
              private route: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta) {
    this.route.data.subscribe(data => {
      this.insiderEntry = <BaseEntry> data.blogEntry;

      this.titleService.setTitle('Portfolio Insight - Dividend Radar Insider');
      this.metaService.addTag({name: 'description', content: this.insiderEntry.synopsis}, true);

      this.content = this.contentService.parseContent(this.insiderEntry);
      this.bioContent = this.contentService.parseAuthor(this.insiderEntry);
    });
  }

  ngOnInit(): void {

  }
}
