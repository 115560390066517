import {DateTime} from 'luxon';

export interface BaseEntry {
  _id: string;
  _rev: string;

  _createdAt: string;
  _updatedAt: string;
  convertedCreated: DateTime;
  localeCreated: string;
  convertedUpdated: DateTime;
  localeUpdated: string;

  title: string;

  image: BlogImage;
  imageUrl: string;
  mediumImageUrl: string;
  largeImageUrl: string;

  author: Reference;
  resolvedAuthor: Author | undefined;

  synopsis: string;
  categories: string[];

  content: ContentBlock[];

  table1: BlogTable;
  table2: BlogTable;
  table3: BlogTable;
  table4: BlogTable;
  table5: BlogTable;
  table6: BlogTable;
  table7: BlogTable;
  table8: BlogTable;
}

export interface BlogEntry extends BaseEntry {
  externalUrl: string;
  pinned: boolean;
}

export interface ContentBlock {
  _key: string;
  _type: 'block' | 'image';
  children: any[];
}

export interface BlogImage {
  asset: Reference;
}

export interface BlogTable {
  rows: BlogTableRow[];
}

export interface BlogTableRow {
  cells: string[];
}

export interface Reference {
  _ref: string;
}

export interface Author {
  _id: string;
  name: string;
  image: AssetImage;
  imageUrl: string;
  bio: ContentBlock[];
}

export interface AssetImage {
  _ref: string;
}
