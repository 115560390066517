import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

// Require the lightbox module so it is included in the Webpack build. The lightbox is in a script included in index.html and invoked by
// sanitized HTML.
declare var require: any;
require('fslightbox');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Portfolio Insight Blog';

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  showIndex() {
    return this.router.url.indexOf('/entries/') >= 0;
  }
}


