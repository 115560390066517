export function getCategoryDescription(category: string) {
  switch (category) {
    case 'stock-analysis':
      return 'Stock Analysis';
    case 'investment-strategy':
      return 'Investment Strategy';
    case 'dividend-ideas':
      return 'Dividend Ideas';
    case 'valuation':
      return 'Stock Valuation';
    case 'feature':
      return 'Portfolio Insight Feature';
    default:
      return '';
  }
}
