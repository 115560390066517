import {Injectable} from '@angular/core';
import {Author, BaseEntry, BlogEntry} from '../domain';
import {DateTime} from 'luxon';

import {imageBuilder, LruCache, sanityClient} from '../util';


@Injectable()
export class BlogRepository {
  private authors: LruCache<Author>;
  private entries: LruCache<BlogEntry>;

  constructor() {
    this.authors = new LruCache<Author>(10);
    this.entries = new LruCache<BlogEntry>(200);
  }

  initialize(): Promise<void> {
    if (this.authors.size() === 0) {
      return sanityClient().fetch(`*[_type=="author"]`).then((r: Author[]) => {
        r.forEach(author => {
          author.imageUrl = imageBuilder().image(author.image).url();
          this.authors.put(author._id, author);
        });
      });
    }
    return Promise.resolve();
  }

  fetchBlogEntries(offset = 0, max = 200, includePinned = false): Promise<BlogEntry[]> {
    const pinnedPromise = includePinned ?
      sanityClient().fetch(`*[_type=="blog-entry" && pinned == true]  | order(_createdAt desc)`) : Promise.resolve([]);

    const entriesPromise = sanityClient().fetch(`*[_type=="blog-entry"]  | order(_createdAt desc) [${offset}..${offset + max}]`);

    return Promise.all([pinnedPromise, entriesPromise]).then((result) => {
      const pinned = <BlogEntry[]> result[0];

      const set = new Set();
      pinned.forEach(entry => set.add(entry._id));

      const entries = (<BlogEntry[]> result[1]).filter(entry => !set.has(entry._id));

      const combined = pinned.concat(entries);
      combined.forEach(entry => this.rehydrateEntry(entry));
      return combined;
    });
  }

  fetchBlogEntry(id: string): Promise<BlogEntry> {
    const entry = this.entries.get(id);
    if (entry != null) {
      return Promise.resolve(entry);
    }
    return sanityClient().fetch(`*[_id=="${id}"][0]`).then((r: BlogEntry) => {
      return this.rehydrateEntry(r);
    });
  }

  fetchInsiderEntry(id: string): Promise<BaseEntry> {
    const entry = this.entries.get(id);
    if (entry != null) {
      return Promise.resolve(entry);
    }
    return sanityClient().fetch(`*[_id=="${id}"][0]`).then((r: BlogEntry) => {
      return this.baseRehydrateEntry(r);
    });
  }

  private rehydrateEntry(entry: BlogEntry) {
    entry = <BlogEntry> this.baseRehydrateEntry(entry);
    this.entries.put(entry._id, entry);
    return entry;
  }

  private baseRehydrateEntry(entry: BaseEntry) {
    entry.convertedCreated = DateTime.fromISO(entry._createdAt);
    entry.localeCreated = entry.convertedCreated.toLocaleString(DateTime.DATE_MED);
    entry.convertedUpdated = DateTime.fromISO(entry._updatedAt);
    entry.localeUpdated = entry.convertedUpdated.toLocaleString(DateTime.DATE_MED);
    entry.resolvedAuthor = this.authors.get(entry.author._ref);
    try {
      entry.imageUrl = imageBuilder().image(entry.image).width(600).height(200).url();
      entry.mediumImageUrl = imageBuilder().image(entry.image).width(800).height(300).url();
      entry.largeImageUrl = imageBuilder().image(entry.image).width(600).height(600).url();
    } catch (e) {
      console.error(e);
    }
    return entry;
  }

}

