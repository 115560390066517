<div class="entry-canvas" *ngIf="insiderEntry">
  <div class="entry-header-container">
    <div><img [src]="insiderEntry.largeImageUrl" alt=""></div>
    <div class="entry-right-container">
      <div class="entry-title">
        <div>
          <div class="entry-category">
            <div>Dividend Radar Insider</div>
          </div>
          <div class="entry-title-text">{{insiderEntry.title}}</div>
        </div>
      </div>
      <div class="caption-container">
        <div class="entry-avatar-container">
          <div class="entry-avatar">
            <img [src]="insiderEntry.resolvedAuthor?.imageUrl" [alt]="insiderEntry.resolvedAuthor?.name">
          </div>
        </div>
        <div class="entry-author-text">
          {{insiderEntry.resolvedAuthor?.name}} &nbsp;&#9679;&nbsp; {{insiderEntry.localeUpdated}}
        </div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div [innerHTML]="content" class="spacer body-text"></div>
  </div>
  <div class="pi-add-box">
    <div class="pi-add-logo-container">
      <div class="pi-add-logo-image-container">
        <img src="https://uploads-ssl.webflow.com/6075f52afcf0b77334010888/6075f52bfcf0b73f00010925_portfolio-insight-fav.png" alt="Portfolio Insight">
      </div>
      <div class="pi-add-heading-container">
        <div class="pi-add-heading">Portfolio Insight</div>
      </div>
    </div>
    <div class="pi-add-caption-container">
      <div class="pi-add-text">The app for</div>
      <div class="pi-add-text">dividend growth investors</div>
    </div>
    <div class="pi-add-button-container">
      <a href="https://app.portfolio-insight.com/ui/assets/register/register.html" class="gradient-button">
        <div class="pi-add-gradient-button">Start your free trial</div>
      </a></div>
  </div>

  <div class="bio-container">
    <div class="entry-avatar">
      <img [src]="insiderEntry.resolvedAuthor?.imageUrl" [alt]="insiderEntry.resolvedAuthor?.name">
    </div>
    <div class="bio-right">
      <div class="entry-category">BIOGRAPHY</div>
      <div class="entry-bio-text" [innerHTML]="bioContent"></div>
    </div>
  </div>
</div>

