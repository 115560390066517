export class LruCache<T> {

  private values: Map<string, T> = new Map<string, T>();
  private maxEntries: number;

  constructor(maxEntries: number) {
    this.maxEntries = maxEntries;
  }

  get(key: string): T | undefined {
    const hasKey = this.values.has(key);
    let entry: T | undefined;
    if (hasKey) {
      // peek the entry, re-insert for LRU strategy
      entry = this.values.get(key);
      if (entry !== undefined) {
        this.values.delete(key);
        this.values.set(key, entry);
      }
    }

    return entry;
  }

  getValues(): Array<T> {
    return Array.from(this.values.values());
  }

  put(key: string, value: T) {
    if (this.values.has(key)) {
      this.values.delete(key);
      this.values.set(key, value);
      return;
    }

    if (this.values.size >= this.maxEntries) {
      // least-recently eviction strategy
      const keyToDelete = this.values.keys().next().value;

      this.values.delete(keyToDelete);
    }

    this.values.set(key, value);
  }

  empty(): boolean {
    return this.values.size === 0;
  }

  size(): number {
    return this.values.size;
  }
}
