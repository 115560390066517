<div class="blog-banner-container">
  <div class="blog-banner">Intelligent Investing</div>
  <div class="blog-sub-banner">Ideas and analysis to boost your portfolio.</div>
</div>
<div class="listing-canvas" infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     (scrolled)="onScroll()">
  <div *ngIf="!smallScreen" class="cards-top-section">
    <div *ngFor="let blogEntry of blogEntries.slice(0,1)" class="article-card" (click)="gotoEntry(blogEntry)">
      <div class="blog-category">
        <div *ngFor="let category of blogEntry.categories">{{categoryDescription(category)}}</div>
      </div>
      <div><img [src]="blogEntry.mediumImageUrl" alt=""></div>
      <div><h4>{{blogEntry.title}}</h4></div>
      <div class="card-info">
        <div class="card-author-img">
          <a href="" class="card-avatar">
            <img [src]="blogEntry.resolvedAuthor?.imageUrl" [alt]="blogEntry.resolvedAuthor?.name">
          </a>
        </div>
        <div class="card-article-data">
          <div>{{blogEntry.resolvedAuthor?.name}}</div>
          <div><span *ngIf="blogEntry.externalUrl">Syndicated &#8226; </span> {{blogEntry.localeCreated}}</div>
        </div>
      </div>
      <div class="synposis">{{blogEntry.synopsis}}</div>
    </div>
  </div>
  <div class="cards-section">
    <div *ngFor="let blogEntry of getRecentEntries()" class="article-card" (click)="gotoEntry(blogEntry)">
      <div class="blog-category">
        <div *ngFor="let category of blogEntry.categories">{{categoryDescription(category)}}</div>
      </div>
      <div><img [src]="blogEntry.imageUrl" alt=""></div>
      <div><h4>{{blogEntry.title}}</h4></div>
      <div class="card-info">
        <div class="card-author-img">
          <a href="" class="card-avatar">
            <img [src]="blogEntry.resolvedAuthor?.imageUrl" [alt]="blogEntry.resolvedAuthor?.name">
          </a>
        </div>
        <div class="card-article-data">
          <div>{{blogEntry.resolvedAuthor?.name}}</div>
          <div><span *ngIf="blogEntry.externalUrl">Syndicated &#8226; </span> {{blogEntry.localeCreated}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pi-add-box">
  <div class="pi-add-logo-container">
    <div class="pi-add-logo-image-container">
      <img src="https://uploads-ssl.webflow.com/6075f52afcf0b77334010888/6075f52bfcf0b73f00010925_portfolio-insight-fav.png" alt="Portfolio Insight">
    </div>
    <div class="pi-add-heading-container">
      <div class="pi-add-heading">Portfolio Insight</div>
    </div>
  </div>
  <div class="pi-add-caption-container">
    <div class="pi-add-text">The app for</div>
    <div class="pi-add-text">dividend growth investors</div>
  </div>
  <div class="pi-add-button-container">
    <a href="https://app.portfolio-insight.com/ui/assets/register/register.html" class="gradient-button">
      <div class="pi-add-gradient-button">Start your free trial</div>
    </a></div>
</div>
