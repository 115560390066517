import {from, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {BlogEntry} from '../domain';
import {BlogRepository} from '../repository/blog-repository';

@Injectable()
export class BlogEntryResolver implements Resolve<Observable<BlogEntry>> {
  constructor(private blogRepository: BlogRepository) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<BlogEntry> {
    return from(this.blogRepository.fetchBlogEntry(route.params.id));
  }
}
