import {Component, HostListener, OnInit} from '@angular/core';
import {BlogRepository} from '../repository/blog-repository';
import {BlogEntry, getCategoryDescription} from '../domain';
import {Router} from '@angular/router';

const MAX_ENTRIES = 2;

@Component({
  selector: 'app-entries-list',
  templateUrl: './entries-list.component.html',
})
export class EntriesListComponent implements OnInit {

  smallScreen = window.innerWidth < 900;
  public blogEntries: BlogEntry[] = [];
  private offset = 0;
  private reachedEnd = false;
  // used to disable on scroll until after the initial entries have been initialized; otherwise duplicates will be retrieved
  private enableOnScroll = false;

  constructor(private repository: BlogRepository, private router: Router) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.smallScreen = window.innerWidth < 900;
  }

  ngOnInit(): void {
    this.enableOnScroll = false;
    this.blogEntries = [];
    this.repository.fetchBlogEntries(this.offset, MAX_ENTRIES, true).then((entries) => {
      this.blogEntries = entries;
      this.offset = entries.length;
      this.reachedEnd = false;
      this.enableOnScroll = true;
    });
  }

  getRecentEntries() {
    return this.smallScreen ? this.blogEntries : this.blogEntries.slice(1);
  }

  categoryDescription(category: string) {
    return getCategoryDescription(category);
  }

  gotoEntry(blogEntry: BlogEntry) {
    if (blogEntry.externalUrl != null) {
      window.open(blogEntry.externalUrl, '_blank');
    } else {
      this.router.navigate(['entries', blogEntry._id]);
    }
  }

  onScroll() {
    if (this.reachedEnd || !this.enableOnScroll) {
      return;
    }
    this.repository.fetchBlogEntries(this.offset, MAX_ENTRIES).then((entries) => {
      if (entries.length === 0) {
        this.reachedEnd = true;
        return;
      }
      // console.log("fetched: " + entries.length);
      this.blogEntries.push(...entries);
      this.offset += entries.length;
    });
  }
}
