import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EntriesListComponent} from './entry/entries-list.component';
import {BlogEntryComponent} from './entry/blog-entry.component';
import {BlogEntryResolver} from './entry/blog-entry.resolver';
import {InsiderEntryComponent} from './insider/insider-entry.component';
import {InsiderEntryResolver} from './insider/insider-entry.resolver';

const routes: Routes = [
  {
    path: '', component: EntriesListComponent,
  },
  {
    path: 'entries/:id',
    component: BlogEntryComponent,
    resolve: {blogEntry: BlogEntryResolver},
  },
  {
    path: 'insider/:id',
    component: InsiderEntryComponent,
    resolve: {blogEntry: InsiderEntryResolver},
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
